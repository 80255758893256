<template>
  <div class="notice-edit">
    <el-row class="title">
      <el-col :span="20">
        <el-input placeholder="请输入内容" v-model="title">
          <template slot="prepend">标题</template>
        </el-input>
      </el-col>
    </el-row>
    <div class="html">
      <quill-editor
        ref="myTextEditor"
        v-model="content"
        :options="editorOption"
        @change="handleEditorChange"
        style="height: 100%"
      />
    </div>
    <!-- <el-row class="file-annex">
      <el-col :span="1" class="left">附件：</el-col>
      <el-col :span="6" class="right">
        <el-upload
          class="upload-demo"
          drag
          action="https://jsonplaceholder.typicode.com/posts/"
          multiple
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
          </div>
        </el-upload>
      </el-col>
    </el-row> -->
    <div class="btn-container" v-show="btnShow">
      <el-button type="primary" @click="handleAddNotice">提交通知</el-button>
    </div>
  </div>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
export default {
  name: "NoticeDetail",
  components: {
    quillEditor,
  },
  data() {
    return {
      btnShow: true,
      title: "",
      content: "",
      editorOption: {
        placeholder: "编辑文章内容",
      },
    };
  },
  created() {
    this.handleInit();
  },
  mounted() {},
  activated() {
    this.handleInit();
  },
  destroyed() {
    sessionStorage.noticeId = "";
    this.btnShow = true;
  },
  methods: {
    handleInit() {
      let noticeId = "";
      if (this.$route.params.id) {
        sessionStorage.noticeId = this.$route.params.id;
        noticeId = this.$route.params.id;
        this.btnShow = false;
      } else if (sessionStorage.noticeId) {
        noticeId = sessionStorage.noticeId;
        this.btnShow = true;
      } else {
        this.btnShow = true;
      }
      if (noticeId) {
        this.getNoticeDetail();
      }
    },
    handleEditorChange({ html }) {
      this.content = html;
    },
    //获取通知详情
    async getNoticeDetail() {
      try {
      } catch (error) {}
      const res = await this.$store.dispatch("getNoticeDetail", {
        announce_id: this.$route.params.id,
      });
      this.content = res.items.content;
      this.title = res.items.title;
    },
    //新增通知
    async handleAddNotice() {
      const res = await this.$store.dispatch("addNotice", {
        title: this.title,
        content: this.content,
      });
      this.$tool.comfirmToast(res).then(() => {
        this.$router.push("/Notice");
      });
    },
  },
};
</script>
<style lang="less" scoped>
.notice-edit {
  height: 100%;
  .title {
    margin-bottom: 20px;
  }
  .html {
    width: 100%;
  }
  .btn-container {
    margin-top: 20px;
    width: 100%;
  }
  .file-annex {
    margin-top: 20px;
    .left {
      font-size: 16px;
    }
  }
}
</style>